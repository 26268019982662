import Swiper, {Autoplay, Pagination} from 'swiper';

export class Certification {
    constructor() {
        const swiper = document.querySelector('.certifications-slider');

        if (swiper) {
            const slider = new Swiper(swiper, {
                slidesPerView: 1,
                spaceBetween: 32,
                modules: [Pagination, Autoplay],
                autoplay: {
                    delay: 5000,
                },
                pagination: {
                    el: '.certifications-slider .swiper-pagination',
                    clickable: false,
                },
                breakpoints: {
                    768: {
                        slidesPerView: 2,
                        spaceBetween: 32,
                    }
                }
            });
        }
    }
}
