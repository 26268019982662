import Swiper, {Autoplay} from "swiper";

export class SlidingText {
    constructor()
    {
        const swiper = new Swiper('.sliding-text', {
            modules: [Autoplay],
            direction: 'vertical',
            loop: true,
            allowTouchMove: false,
            autoplay: {
                delay: 1000,
                reverseDirection: true,
            },
            on: {
                init: function () {
                    window.dispatchEvent(new Event('resize'))
                },
            },
        });
    }
}
