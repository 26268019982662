export class ScrollTo {
    constructor(scroller)
    {
        const scrollTos = document.querySelectorAll('[data-scroll-to]')
        if (scrollTos.length) {
            scrollTos.forEach((s) => {
                const to = s.getAttribute('data-scroll-to')
                s.addEventListener('click', e => {
                    e.preventDefault()
                    if (scroller) {
                        scroller.scrollTo(to, {
                            offset: -200
                        })
                    }
                })
            })
        }
    }
}
