import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger)

export class AppearingAnimation {
    constructor()
    {
        document.querySelectorAll('*[appear-animation]').forEach(el => {
            const animation = el.getAttribute('appear-animation');
            if (!animation) return;

            const tl = gsap.timeline({
                scrollTrigger: {
                    trigger: el,
                    start: "top bottom",
                    ease: "power2",
                    toggleActions: "play none none reverse"
                }
            })

            switch (animation) {
                case 'fadeInUp':
                    tl
                        .from(el, {
                            y: 10,
                            opacity: 0,
                        })
                        .to(el, {
                            y: 0,
                            opacity: 1,
                        })
                    break;
                case 'fadeInLeft':
                    tl
                        .from(el, {
                            x: -10,
                            opacity: 0,
                        })
                        .to(el, {
                            x: 0,
                            opacity: 1,
                        })
                    break;
                case 'fadeInRight':
                    tl
                        .from(el, {
                            x: 10,
                            opacity: 0,
                        })
                        .to(el, {
                            x: 0,
                            opacity: 1,
                        })
                    break;
            }
        })
    }
}
