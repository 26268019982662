// Gets the mouse position
const getMousePos = (e) => {
    let posx = 0;
    let posy = 0;
    if (!e) {
        e = window.event;
    }
    if (e && (e.clientX || e.clientY)) {
        posx = e.clientX;
        posy = e.clientY;
    }

    return { x : posx, y : posy }
};

const wrapAll = (wrapel, elms) => {
    const el = elms.length ? elms[0] : elms;

    // Cache the current parent and sibling of the first element.
    const parent  = el.parentNode;
    const sibling = el.nextSibling;

    // Wrap the first element (is automatically removed from its
    // current parent).
    wrapel.appendChild(el);

    // Wrap all other elements (if applicable). Each element is
    // automatically removed from its current parent and from the elms
    // array.
    elms.forEach(el => wrapel.appendChild(el))

    // If the first element had a sibling, insert the wrapper before the
    // sibling to maintain the HTML structure; otherwise, just append it
    // to the parent.
    if (sibling) {
        parent.insertBefore(wrapel, sibling);
    } else {
        parent.appendChild(wrapel);
    }
}

export { getMousePos, wrapAll };
