import Matter from 'matter-js';
import 'pathseg'
import * as Decomp from 'poly-decomp';

const Engine = Matter.Engine,
    Render = Matter.Render,
    Runner = Matter.Runner,
    Bodies = Matter.Bodies,
    Mouse = Matter.Mouse,
    Svg = Matter.Svg,
    Common = Matter.Common,
    MouseConstraint = Matter.MouseConstraint,
    Composite = Matter.Composite;

Common.setDecomp(Decomp)

const h = 325;
const w = 513.61;

const white = '#fff';

export class OurValues {
    constructor()
    {
        this.count = 0;
        this.runner = Runner.create();
        this.resetBtns = document.querySelectorAll('*[matter-reset]');
        this.triggers = document.querySelectorAll('*[matter-trigger]');
        this.createWorld()
        this.manage()
    }

    customShape(x, y, shape, color, angle = 0) {
        // let vertices = Matter.Vertices.fromPath(shape);
        return Matter.Bodies.fromVertices(x, y, shape, {
            // isStatic: true,
            angle,
            render: {
                fillStyle: color,
                strokeStyle: color,
                lineWidth: 1,
            }
        }, true);
    }

    async createWorld() {
        const matter = document.querySelector('*[matter]')

        this.engine = Engine.create();
        const world = this.engine.world;

        this.render = Render.create({
            element: matter,
            engine: this.engine,
            options: {
                width: w,
                height: h,
                wireframes: false,
                background: white
            }
        });

        this.rect = Bodies.rectangle(w/2, h/2, 498.66, 74.8, {
            // isStatic: true,
            angle: 0.122173,
            render: {
                fillStyle: "#000F9F",
            }
        });
        // this.semiCircle = this.customShape(w/2, h - 50, shapeSemiCircle, "#0071CE");
        // this.semiCircleNotFilled = this.customShape(w - 115, 70, shapeSemiCircleNotFilled, "#001A70", 0.05);

        this.semiCircle = await this.loadSvg('/app/themes/akyos/resources/assets/images/svg/semicircle.svg').then(root => {
            const vertexSets = this.select(root, 'path')
                .map(p => Svg.pathToVertices(p, 5));
            const shape = this.customShape(w/2, h - 50, vertexSets, "#0071CE");

            Composite.add(world, shape);

            return shape;
        });
        this.semiCircleNotFilled = await this.loadSvg('/app/themes/akyos/resources/assets/images/svg/semicirclenotfilled.svg').then(root => {
            const vertexSets = this.select(root, 'path')
                .map(p => Svg.pathToVertices(p, 5));
            const shape = this.customShape(w - 115, 70, vertexSets, "#001A70", 0.05)

            Composite.add(world, shape);

            return shape;
        });

        this.square = Bodies.rectangle(80, 50, 70.1324, 70.1324, {
            angle: -0.90757121,
            render: {
                fillStyle: "#5EB3E4",
            }
        });

        const wallRight = Bodies.rectangle(w, 0, 1, h*2, {
            isStatic: true,
            render: { fillStyle: white }
        });
        const wallLeft = Bodies.rectangle(0, 0, 1, h*2, {
            isStatic: true,
            render: { fillStyle: white }
        });
        const ground = Bodies.rectangle(0, h, w*2, 1, {
            isStatic: true,
            render: { fillStyle: white }
        });

        const mouse = Mouse.create(this.render.canvas),
            mouseConstraint = MouseConstraint.create(this.engine, {
                mouse: mouse,
                constraint: {
                    stiffness: 0.2,
                    render: {
                        visible: false
                    }
                }
            });

        Composite.add(world, [this.rect, this.square, wallLeft, wallRight, ground, mouseConstraint]);

        this.render.mouse = mouse;

        this.init = world

        Render.run(this.render);
    }

    manage() {
        this.triggers.forEach(el => {
            const form = el.getAttribute('matter-trigger')
            el.addEventListener('click', evt => {
                evt.preventDefault()

                el.setAttribute('hidden', '')

                let obj = false;
                switch (form) {
                    case 'rect':
                        obj = this.rect;
                        break;
                    case 'square':
                        obj = this.square;
                        break;
                    case 'semi_circle':
                        obj = this.semiCircle;
                        break;
                    case 'semi_circle_unfilled':
                        obj = this.semiCircleNotFilled;
                        break;
                }

                if (this.count === 0) {
                    this.resetBtns.forEach(r => {
                        r.removeAttribute('hidden')
                    })
                }

                if (obj) {
                    Composite.remove(this.engine.world, obj)
                    Runner.run(this.runner, this.engine)
                    this.count++
                }
            })
        })

        this.resetBtns.forEach(el => {
            el.addEventListener('click', evt => {
                evt.preventDefault()

                this.resetWorld();
            })
        })
    }

    resetWorld()
    {
        Composite.clear(this.engine.world);
        Engine.clear(this.engine);
        Render.stop(this.render);
        Runner.stop(this.runner);
        this.render.canvas.remove();
        this.render.canvas = null;
        this.render.context = null;
        this.render.textures = {};

        this.resetBtns.forEach(el => {
            el.setAttribute('hidden', '')
        })

        this.triggers.forEach(el => {
            el.removeAttribute('hidden')
        })

        this.count = 0;

        this.createWorld()
    }

    loadSvg (url) {
        return fetch(url)
            .then(function(response) { return response.text(); })
            .then(function(raw) { return (new window.DOMParser()).parseFromString(raw, 'image/svg+xml'); });
    }

    select (root, selector) {
        return Array.prototype.slice.call(root.querySelectorAll(selector));
    };
}
