import Splitting from 'splitting'
import {wrapAll} from '../utils'
import gsap from 'gsap';

export class SplittingTitleAnimation {
    constructor(scrollerContainer)
    {
        const results = Splitting({
            target: "[splitting-animation]",
            by: 'lines',
            whitespace: true,
        });

        results.map((entry) => {
            entry.lines.map(l => {
                const span_wrap = document.createElement('div')
                span_wrap.classList = 'overflow';
                wrapAll(span_wrap, l)
            })

            const tl = gsap.timeline({
                scrollTrigger: {
                    scroller: scrollerContainer,
                    trigger: entry.el,
                    start: "top center",
                    end: "bottom center"
                },
            })
            .from(entry.lines, {
                duration: 0.8,
                y: '100%',
                ease: "power4",
                stagger: 0,
            })
        })
    }
}
