export class Timeline {
    constructor()
    {
        document.querySelectorAll('*[data-trigger-anchor]').forEach(el => {
            const endpoint = el.getAttribute('data-trigger-anchor')
            this.observe(el, endpoint)
        })

        const aside = document.querySelector('#TimelineAside');
        if (aside) {
            const ol = aside.querySelector('ol');

            (new IntersectionObserver(
                ([e]) => {
                    if(e.isIntersecting) {
                        ol.classList.add('is-inview')
                    } else {
                        ol.classList.remove('is-inview')
                    }
                },
                {
                    root: document,
                    rootMargin: "-50% 0px -50% 0px",
                    threshold: [0]
                }
            )).observe(aside)
        }
    }

    observe(el, endpoint)
    {
        (new IntersectionObserver(
            ([e]) => {
                if(e.isIntersecting) {
                    const els = document.querySelectorAll(`*[data-endpoint-anchor=${endpoint}]`)
                    this.removeClass()
                    els.forEach(l => {
                        l.classList.add('active')
                    })
                }
            },
            {
                root: document,
                rootMargin: "-50% 0px -50% 0px",
                threshold: [0]
            }
        )).observe(el)
    }

    removeClass()
    {
        document.querySelectorAll('*[data-endpoint-anchor]').forEach(l => {
            l.classList.remove('active')
        })
    }
}
