export class Wheel {
    constructor(scroller)
    {
        const wheel = document.querySelector('.wheel')
        if (wheel) {
            wheel.querySelectorAll('.wheel-item').forEach(item => {
                item.addEventListener('mouseenter', evt => {
                    wheel.setAttribute('data-label', item.getAttribute('data-label'))
                })
                item.addEventListener('mouseleave', evt => {
                    wheel.removeAttribute('data-label')
                })
            })
        }
    }
}
