import gsap from "gsap";

import {getMousePos} from "../utils";

let mousePos = getMousePos();

export class MouseMovingElement {
    constructor()
    {
        document.querySelectorAll('*[mouse-mouving]').forEach(el => {
            el.querySelectorAll('*[mouse-mouving-target]').forEach(el => {
                let imgX = 0;
                let imgY = 0;
                let speed = 0.02;

                function moveImg () {
                    let distX = mousePos.x - imgX;
                    let distY = mousePos.y - imgY;

                    imgX = imgX + (distX * speed);
                    imgY = imgY + (distY * speed);

                    const offset = el.getAttribute('data-animation-offset')
                    gsap.to(el, {x: (imgX / parseInt(offset)), y: (imgY / parseInt(offset))})

                    requestAnimationFrame(moveImg);
                }
                moveImg()
            })

            el.addEventListener('mousemove', e => {
                mousePos = getMousePos(e)
            })
        })
    }
}
