import gsap from 'gsap';
import Draggable from 'gsap/Draggable';

gsap.registerPlugin(Draggable);

export class Grabbing {
    constructor(scroller)
    {
        const container = document.querySelector('*[grabbing-container]')

        if (container) {
            const draggables = container.querySelectorAll('*[grabbing-element]')

            Draggable.create(draggables, {
                bounds: container,
                edgeResistance: 0.65,
                type: "x,y",
                inertia: 0.6,
                autoScroll: true,
                snap:{
                    x: (endValue) => endValue,
                    y: (endValue) => endValue
                },
                allowEventDefault: true,
                onDragStart: () => scroller? scroller.stop() : null,
                onDragEnd: () => scroller? scroller.start() : null,
            });
        }
    }
}
