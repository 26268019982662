import gsap from "gsap"
import ScrollTrigger from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger);

export class ScrollPanel {
    constructor()
    {
        if(window.innerWidth >= 991) {
            let panels = gsap.utils.toArray("*[data-panel]")
            const header = document.querySelector('header.banner')
            const hh = header.offsetHeight + 70

            let tl = gsap.timeline({
                scrollTrigger: {
                    scroller: "#Main",
                    trigger: "#Panels",
                    start: "top +="+hh,
                    scrub: true,
                    pin: true,
                    // markers: true,
                }
              });

            panels.forEach((panel, i) => {
                tl.fromTo(panel, {yPercent: 100 * i}, {yPercent: 2 * i})
            });
        }
    }
}
