export class HoverTextChange {
    constructor()
    {
        document.querySelectorAll('*[hover-text-change]').forEach(el => {
            const endpoint = el.getAttribute('hover-text-change');
            const endpoint_el = document.querySelector(endpoint);
            if (!endpoint_el) return;

            el.addEventListener('click', () => {
                endpoint_el.innerHTML = el.getAttribute('tooltip');
            })
        })
    }
}
