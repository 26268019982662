import gsap from "gsap";
import {ScrollToPlugin} from "gsap/ScrollToPlugin";
import {ScrollTrigger} from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollToPlugin, ScrollTrigger);

export class Horizontal {
    constructor()
    {
        document.querySelectorAll(".l-horizontal").forEach((container) => {
            const wrapper = container.querySelector(".l-horizontal-wrapper");

            if (wrapper) {
                let mm = gsap.matchMedia(),
                    breakPoint = 992;
                mm.add({
                    isDesktop: `(min-width: ${breakPoint}px) and (prefers-reduced-motion: no-preference)`,
                    isMobile: `(max-width: ${breakPoint - 1}px) and (prefers-reduced-motion: no-preference)`
                }, (context) => {
                    let { isDesktop, isMobile } = context.conditions

                    if (isDesktop) {
                        gsap.to(wrapper, {
                            x: () => -1 * (container.scrollWidth - innerWidth),
                            ease: "none",
                            scrollTrigger: {
                                trigger: container,
                                pin: true,
                                markers: false,
                                start: 'top 50px',
                                scrub: 1,
                                end: () => "+=" + (container.scrollWidth - innerWidth),
                                onUpdate: (self) => {
                                    if (window.innerWidth < 1200) {
                                        self.kill();
                                    }
                                }
                            }
                        });
                    }

                    return () => {
                    }
                });
            }
        });
    }
}
