const CACHE = 'needs_cache';

export class Needs {
    async register()
    {
        const needs = document.querySelector('.needs')
        if (needs) {
            const endpoint = needs.querySelector('*[needs-endpoint]')
            const els = needs.querySelectorAll('.needs-list > *[needs-el]')
            // const storage = new CacheStorage();
            // const cache = await storage.open(CACHE)
            const img = document.createElement('img')

            els.forEach(el => {
                const content = el.getAttribute('needs-el')
                // const request = new Request(content)
                // cache.add(request)

                el.addEventListener('mouseenter', async (evt) => {
                    // const res = await cache.match(request)
                    img.setAttribute('src', content)
                    endpoint.appendChild(img)
                })
            })
        }
    }
}
