import { textVide } from 'text-vide';

export class BionicRead {
    constructor()
    {
        const texts = document.querySelectorAll('*[bionic-read-endpoint]')

        document.querySelectorAll('*[bionic-read-toggle]').forEach(b => {
            b.addEventListener('click', evt => {
                evt.preventDefault()
                b.classList.toggle('active')

                texts.forEach(t => {
                    const initText = t.getAttribute('data-init-text');
                    if (initText !== null && initText !== 'null' && initText) {
                        t.setAttribute('data-init-text', null);
                        t.innerHTML = initText
                    } else {
                        t.setAttribute('data-init-text', t.innerHTML);
                        t.innerHTML = textVide(t.innerHTML, {fixationPoint: 3})
                    }
                    t.innerHTML = t.innerHTML.replaceAll('&amp;<b>nb</b>sp;', ' ');
                })
            })
        })
    }
}
