import moment from "moment";

export class Archive {
    constructor(scroller)
    {
        this.scroller = scroller
        this.container = document.querySelector('*[data-archive]')
        if (!this.container) {
            return;
        }
        this.type = this.container.getAttribute('data-archive')

        this.moreBtn = this.container.querySelector('*[data-archive-btn-more]');
        this.moreBtnInitText = this.moreBtn.innerHTML
        this.filters_container = this.container.querySelector('*[data-filters]');
        this.filters_type = this.filters_container.getAttribute('data-filters');
        this.filters = this.filters_container.querySelectorAll('*[data-filter]');
        this.itemsContainer = this.container.querySelector('*[data-archive-endpoint]')
        this.init()
    }

    init()
    {
        this.config = this.defaults()
        this.url = `${window.location.protocol}//${window.location.hostname}/wp-json/akyos/v1/${this.type}`
        this.register()
    }

    defaults()
    {
        return {
            itemsperpage: parseInt(this.container.dataset.itemsperpage),
            itemsoffset: parseInt(this.container.dataset.itemsperpage),
            currentpage: 1,
            noMoreItems: false,
            currentTax: false,
        }
    }

    reset()
    {
        this.filters.forEach(f => f.classList.remove('active'))
        this.config = this.defaults()
        this.itemsContainer.innerHTML = "";
        this.moreBtn.innerHTML = this.moreBtnInitText;
        this.moreBtn.removeAttribute('hidden');
    }

    register()
    {
        this.filters.forEach(f => {
            f.addEventListener('click', (e) => {
                const id = f.dataset.filter
                e.preventDefault();
                this.reset()

                f.classList.add('active')

                this.appendSkeletons()
                this.config.currentTax = id
                this.fetch(true)
            })
        })

        this.moreBtn.addEventListener('click', (e) => {
            e.preventDefault();
            this.config.currentpage += 1;
            if (this.config.noMoreItems) {
                return;
            }

            this.fetch();
        })
    }

    fetch(reset = false)
    {
        const params = new URLSearchParams();
        params.append('per_page', this.config.itemsperpage)
        params.append('page', this.config.currentpage)
        if (this.config.currentTax && this.config.currentTax !== "*") {
            params.append(this.filters_type, this.config.currentTax)
        }

        fetch(`${this.url}?${params.toString()}`)
            .then((r) => r.json())
            .then(r => {
                if (reset) {
                    this.itemsContainer.innerHTML = "";
                }

                if (r.length === 0 || r.length < this.config.itemsperpage) {
                    this.moreBtn.innerHTML = 'Aucun résultat trouvé';
                    this.config.noMoreItems = true;
                    this.moreBtn.setAttribute('hidden', '')
                } else {
                    this.moreBtn.removeAttribute('hidden')
                }

                if (r.length === 0 && this.config.currentpage === 1) {
                    this.moreBtn.innerHTML = 'Aucun évènement';
                    this.config.noMoreItems = true;
                    this.moreBtn.removeAttribute('hidden')
                }

                this.appendItems(r);
            })
            .catch(r => {
                console.log(r)
                this.moreBtn.setAttribute('hidden', '')
                this.moreBtn.innerHTML = 'Aucun résultat trouvé';
                this.config.noMoreItems = true;
            })
    }

    appendSkeletons()
    {
        const template = document.querySelector('#skeleton');

        for (let i = 0; i < 3; i++) {
            let clone = template.content.cloneNode(true);
            this.itemsContainer.appendChild(clone)
        }

        if(this.scroller) {
            this.scroller.update()
        }
    }

    appendItems(items)
    {
        const template = document.querySelector('#archive-el');

        items.forEach((item) => {
            let clone = template.content.cloneNode(true);

            switch (this.type) {
                case 'realisation':
                    clone = this.itemRealisation(clone, item)
                    break;

                case 'post':
                    clone = this.itemPost(clone, item)
                    break;

                case 'formation':
                    clone = this.itemFormation(clone, item)
                    break;
            }

            this.itemsContainer.appendChild(clone)
        })

        if(this.scroller) {
            this.scroller.update()
        }
    }

    itemRealisation(clone, item)
    {
        const link = clone.querySelector('.c-realisation--link');
        const thumb = clone.querySelector('.c-realisation-thumb');
        const excerpt = clone.querySelector('.c-realisation__excerpt');
        const infos = clone.querySelector('.c-realisation-infos');
        const title = clone.querySelector('.title');

        const thumbnail = document.createElement('img')
        thumbnail.setAttribute('src', item.thumbnail)

        if(item.realisation_types) {
            item.realisation_types.forEach(t => {
                const badge = document.createElement('span')
                badge.setAttribute('class', "badge")
                if (t.acf.color) {
                    badge.setAttribute('style', `--bg:${t.acf.color};`)
                }
                badge.innerHTML = t.name

                infos.prepend(badge)
            })
        }

        link.setAttribute('href', item.link)
        thumb.appendChild(thumbnail)
        if(excerpt) excerpt.innerHTML = item.post_excerpt
        if(title) title.innerHTML = item.post_title

        return clone
    }

    itemPost(clone, item)
    {
        const thumb = clone.querySelector('.post-thumb');
        const title = clone.querySelector('.post__title');
        const excerpt = clone.querySelector('.post__excerpt');
        const links = clone.querySelectorAll('.post-link');

        const author_thumb = clone.querySelector('.profile-thumb')
        const author_title = clone.querySelector('.profile__title')
        const date = clone.querySelector('.profile__job')

        const thumbnail = document.createElement('img')
        thumbnail.setAttribute('src', item.thumbnail)
        thumb.appendChild(thumbnail)

        if(excerpt) excerpt.innerHTML = item.post_excerpt
        if(title) title.innerHTML = item.post_title
        if(date) date.innerHTML = moment(new Date(item.post_date)).format('DD/MM/YYYY')

        if(links) {
            links.forEach( (link) => {
                link.setAttribute('href', item.link)
            });
        }

        if (item.author) {
            if (author_title) author_title.innerHTML = item.author.post_title
            const thumbnail_author = document.createElement('img')
            thumbnail_author.setAttribute('src', item.author.thumbnail)
            if (author_thumb) author_thumb.appendChild(thumbnail_author)
        }

        return clone
    }

    itemFormation(clone, item)
    {
        const title_container = clone.querySelector('.formation-title');
        const title = clone.querySelector('.formation__title');
        const code = clone.querySelector('.formation-info__code');
        const price = clone.querySelector('.formation-info__price');
        const content = clone.querySelector('.formation-info__content');
        const contact_link = clone.querySelector('.formation-contact-link');
        const link = clone.querySelector('.formation-link');

        if (item.formation_categories) {
            item.formation_categories.forEach(c => {
                const badge = document.createElement('span')
                badge.setAttribute('class', "badge")
                if (c.acf.color) {
                    badge.setAttribute('style', `--bg:${c.acf.color};`)
                }
                badge.innerHTML = c.name

                title_container.prepend(badge)
            })
        }

        if(title) title.innerHTML = item.post_title
        if(code) code.innerHTML = item.acf.code
        if(price) price.innerHTML = `${item.acf.duration} - ${item.acf.price}`
        if(content) content.innerHTML = item.post_content

        if(contact_link) {
            if (item.acf.contact_link.title) {
                contact_link.innerHTML = item.acf.contact_link.title
            }
            if (item.acf.contact_link.target) contact_link.setAttribute('target', item.acf.contact_link.target)
            if (item.acf.contact_link.url) contact_link.setAttribute('href', item.acf.contact_link.url)
        }

        if(link) {
            link.innerHTML = item.acf.link.title
            link.setAttribute('target', item.acf.link.target)
            link.setAttribute('href', item.acf.link.url)
        }

        return clone
    }
}
