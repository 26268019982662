import Swiper, { Navigation, Scrollbar } from 'swiper';

export class OurServices {
    constructor()
    {
        const swiper = new Swiper('.our-services-slider', {
            modules: [Navigation, Scrollbar],
            slidesPerView: 'auto',
            spaceBetween: 109,
            scrollbar: {
                el: '.pagination',
                hide: false,
                draggable: true,
            },
            navigation: {
                nextEl: '.navigation .navigation-next',
                prevEl: '.navigation .navigation-prev',
            },
            on: {
                init: function () {
                    window.dispatchEvent(new Event('resize'))
                },
            },
        });
    }
}
