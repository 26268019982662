import gsap from "gsap";

import LocomotiveScroll from 'locomotive-scroll';
import ScrollTrigger from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger);

let scrollerContainer = null;

let scroller = null;
const setScroller = (sc) => {
  scroller = sc
  return sc ? sc : null;
};

const setProxy = (container) => {
  const locomotive = new LocomotiveScroll({
    el: scrollerContainer = document.querySelector(container),
    smooth: true,
    inertia: 0.6,
    multiplier: 1.3,
    smartphone: {
      breakpoint: 0,
      smooth: true,
      inertia: 0.4,
      getDirection: true,
    },
    tablet: {
      breakpoint: 0,
      smooth: true,
      inertia: 0.5,
      getDirection: true,
    },
  });

  locomotive.on("scroll", ScrollTrigger.update);

  ScrollTrigger.scrollerProxy(container, {
    scrollTop(value) {
      return arguments.length
        ? locomotive.scrollTo(value, 0, 0)
        : locomotive.scroll.instance.scroll.y;
    },

    getBoundingClientRect() {
      return {
        top: 0,
        left: 0,
        width: window.innerWidth,
        height: window.innerHeight,
      };
    },

    pinType: document.querySelector(container).style.transform
      ? "transform"
      : "fixed",
  });

  ScrollTrigger.addEventListener("refresh", () => locomotive.update());
  ScrollTrigger.refresh();

  window.addEventListener("resize", () => locomotive.update());

  // imagesLoaded(".smooth-scroll", { background: true }, function () {
  //   setTimeout(function () {
  //     locomotive.update();
  //     alert("truc");
  //   }, 3000);
  // });

  // ScrollTrigger.addEventListener("wp_lazy_loading_enabled", function () {
  //   locomotive.update();
  // });

  return locomotive;
}

export {
  scroller as scroller,
  scrollerContainer as scrollerContainer,
  setScroller as setScroller,
  setProxy as setProxy,
}
