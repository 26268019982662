import gsap from 'gsap';
import {MouseMovingElement} from "./MouseMovingElement";

export class Hero {
    constructor()
    {
        const hero = document.querySelector('.hero')
        if (hero) {
            const trigger = hero.querySelector('*[hero-trigger]')
            if (!trigger) {
                return;
            }

            const bg = document.querySelector('.hero-bg')

            const svgs = bg.children

            const nt1 = svgs[0];
            const nt2 = svgs[1];
            const nt3 = svgs[2];
            const nt4 = svgs[3];
            const nt5 = svgs[4];
            const nt6 = svgs[5];

            this.tl = gsap.timeline({ paused:true });
            this.to(nt1, '36.55%', '17.89%', 0.05, .5);
            this.to(nt2, '65.5%', '30.35%', -300.33, .85);
            this.to(nt3, '59.85%', '58.8498%', -45.76, 1.2);
            this.to(nt4, '26.73%', '41.07%', -15.15, .65);
            this.to(nt5, '23.5%', '60.37%', 90.05, 1.5);
            this.to(nt6, '18.41%', '30.93%', 53, .9);

            trigger.addEventListener('mouseenter', evt => {
                this.tl.play()
            })

            trigger.addEventListener('mouseleave', evt => {
                this.tl.reverse()
            })
        }
    }

    to(el, left, top, rotation, scale = 1)
    {
        this.tl.to(el, {left, top, rotation, scale}, 0)
    }
}
