import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export class Parallax
{
    constructor()
    {
        const parallaxItems = document.querySelectorAll('[data-scroll-speed]');
        const p = 100;

        parallaxItems.forEach(item => {
            const speed = item.getAttribute('data-scroll-speed')

            const tl = gsap.timeline({
                scrollTrigger: {
                    trigger: item,
                    scrub: true,
                }
            })

            tl.set(item, {y: p})
            tl.to(item, {y: p * -speed, ease: "power1.out"})
        });
    }
}
