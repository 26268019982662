import '@css/main.scss';

import {domReady} from '@roots/sage/client';
import {scrollerContainer} from './utils/locomotiveScroll';
import {OurServices} from "./components/OurServices";
import {OurTeam} from "./components/OurTeam";
import {OurValues} from "./components/OurValues";
import {Realisations} from "./components/Realisations";
import {Needs} from "./components/Needs";
import {Hero} from "./components/Hero";
import {Header} from "./components/Header";
import {SlidingText} from "./components/SlidingText";
import {MouseMovingElement} from "./components/MouseMovingElement";
import {SplittingTitleAnimation} from "./components/SplittingTitleAnimation";
import {Archive} from "./components/Archive";
import {BionicRead} from "./components/BionicRead";
import {Grabbing} from "./components/Grabbing";
import {ScrollPanel} from "./components/ScrollPanel";
import {ScrollTo} from "./components/ScrollTo";
import {Timeline} from "./components/Timeline";
import {Wheel} from './components/Wheel';
import {Parallax} from './components/Parallax';
import {HoverTextChange} from './components/HoverTextChange';
import {AppearingAnimation} from './components/AppearingAnimation';
import {Certification} from "./components/Certification";
import {SliderInfos} from "./components/SliderInfos";
import {Horizontal} from "./components/Horizontal";

/**
 * app.main
 */
const main = async (err) => {
    if (err) {
        // handle hmr errors
        console.error(err);
    }

    const scroller = null
    new OurServices()
    new OurTeam()
    const needs = new Needs()
    await needs.register()
    new Hero()
    new Header(scroller)
    new SlidingText()
    new OurValues()
    new Realisations()
    new MouseMovingElement()
    new SplittingTitleAnimation(scrollerContainer)
    new Archive(scroller)
    new BionicRead()
    new Grabbing(scroller)
    // new ScrollPanel()
    new ScrollTo(scroller)
    new Timeline()
    new Wheel()
    new Parallax()
    new HoverTextChange()
    new AppearingAnimation()
    new Certification()
    new SliderInfos()
    new Horizontal()
};

/**
 * Initialize
 *
 * @see https://webpack.js.org/api/hot-module-replacement
 */
domReady(main);
if(import.meta.webpackHot) {
    import.meta.webpackHot.accept(main);
}
