import Swiper, {EffectCoverflow, Navigation, Pagination} from "swiper";

export class SliderInfos {
    constructor()
    {
        const sliderInfos = document.querySelector('.slider_infos--swiper');

        if (sliderInfos) {
            this.init();
        }
    }

    init()
    {
        const slider = new Swiper('.slider_infos--swiper', {
            loop: true,
            slidesPerView: 1,
            spaceBetween: 11,
            centeredSlides: true,
            modules: [Navigation, EffectCoverflow, Pagination],
            effect: 'coverflow',
            coverflowEffect: {
                rotate: 0,
                stretch: 0,
                depth: 10,
                modifier: 1,
                slideShadows: false
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },
            breakpoints: {
                768: {
                    slidesPerView: 5,
                    spaceBetween: -135,
                },
                350: {
                    slidesPerView: 2,
                    spaceBetween: 11,
                }
            }
        });

        let arraySlideIndex = [
            3,
            4,
        ]

        let currentIndex = slider.activeIndex;
        const slides = document.querySelectorAll('.swiper-slide');

        arraySlideIndex.forEach(function (item) {
            slides[currentIndex + item]?.classList.add('third-slide-after');
            slides[currentIndex - item]?.classList.add('third-slide-before');
        })

        slider.on('slideChange', function () {
            slides.forEach(item => item.classList.remove('third-slide-before', 'third-slide-after'));
            currentIndex = slider.activeIndex;

            arraySlideIndex.forEach(function (item) {
                slides[currentIndex + item]?.classList.add('third-slide-after');
                slides[currentIndex - item]?.classList.add('third-slide-before');
            })
        })
    }
}
